import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, DocumentReference } from '@angular/fire/firestore';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

export interface Getankt {
  id?: string,
  timestamp: string,
  car: string,
  amount: string,
  cost: string,
  notes: string
}

@Injectable({
  providedIn: 'root'
})
export class TankenService {
  private tanken: Observable<Getankt[]>;
  private tankenCollection: AngularFirestoreCollection<Getankt>;

  constructor(private afs: AngularFirestore) {
    this.tankenCollection = this.afs.collection<Getankt>('tanken');
    this.tanken = this.tankenCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }

  getTanken(): Observable<Getankt[]> {
    return this.tanken;
  }

  getTank(id: string): Observable<Getankt> {
    return this.tankenCollection.doc<Getankt>(id).valueChanges().pipe(
      take(1),
      map(tank => {
        tank.id = id;
        return tank
      })
    );
  }

  addTank(tank: Getankt): Promise<DocumentReference> {
    return this.tankenCollection.add(tank);
  }

  updateTank(tank: Getankt): Promise<void> {
    return this.tankenCollection.doc(tank.id).update({
      timestamp: tank.timestamp,
      car: tank.car,
      amount: tank.amount,
      cost: tank.cost,
      notes: tank.notes
    });
  }

  deleteTank(id: string): Promise<void> {
    return this.tankenCollection.doc(id).delete();
  }
}
